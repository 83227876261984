<p-dialog [(visible)]="displayTeamDialog" header="Manage teams" [responsive]="true" [modal]="true" [width]="900"
    [resizable]="false" (visibleChange)="visibleChange($event)" (onHide)="close()">
    <div class="team-modal-content ui-grid ui-grid-responsive ui-fluid">
        <div class="top-section">
            <label (click)="addTeam()" class="add-team"><i class="icon-plus p-r-10"></i>Add new team</label>
            <label *ngIf="isMatchplay" (click)="addTeam()" class="add-team">
                <i class="icon-refresh p-r-10"></i>Sync Players
            </label>
            <div class="message" *ngIf="showMessage">{{ messageText }}</div>
            <label class="f-right">No. of teams: {{_teams?.length}}</label>
            <div class="teams-header ui-grid-row m-t-15">
                <div class="ui-grid-col-1 t-center">Icon</div>
                <div class="ui-grid-col-4 t-center">Team Name</div>
                <div class="ui-grid-col-4 t-center">Leaderboard Categories</div>
                <div class="ui-grid-col-2 t-center">Colour</div>
                <div class="ui-grid-col-1 t-center">&nbsp;</div>
            </div>
        </div>
        <div class="content-section">
            <div class="team ui-grid-row" [ngClass]="{'new-row': team.highlighted}"
                *ngFor="let team of _teams; let index = index">
                <div class="ui-grid-col-1 t-center logo-cell">
                    <span *ngIf="team.logoFilename" class="delete-icon" (click)="deleteClick(index)"><i
                            class="fa fa-times-circle-o"></i></span>
                    <team-logo-upload class="team-logo-upload" [teamID]="team.teamID"
                        [competitionID]="team.competitionID"
                        (fileUploaded)="reloadTeam(team.teamID)"></team-logo-upload>
                    <i class="icon-picture add-logo" *ngIf="!team.logoFilename"></i>
                    <div class="team-logo" *ngIf="team.logoFilename">
                        <img class="teamLogo" [src]="getLogoUrl(team)" />
                    </div>
                </div>
                <div class="ui-grid-col-4 t-center p-l-5 p-r-5">
                    <input type="text" pInputText placeholder="Enter team name" [(ngModel)]="team.title"
                        [ngClass]="{'error-field': showError && (team.title.trim() === '' || checkNameRepeated(team.title,team.teamID))}" />
                    <label *ngIf="showError && team.title.trim() === ''" class="error-label">Please enter a name</label>
                    <label *ngIf="showError && checkNameRepeated(team.title,team.teamID)" class="error-label">Team name
                        can't be repeated</label>
                </div>
                <div class="ui-grid-col-4 p-l-5 p-r-5">
                    <p-chips [(ngModel)]="team.categoryArray" allowDuplicate="false" addOnTab="true"></p-chips>
                </div>
                <div class="ui-grid-col-2 t-center p-l-5 p-r-0">
                    <p-dropdown [options]="teamColoursSelect" [(ngModel)]="team.colour" [filter]="false" name="colour"
                        [autoWidth]="false">
                        <ng-template let-item pTemplate="selectedItem">
                            <div class="selected-color">
                                <span *ngIf="item.value !== ''" class="color-ball"
                                    [style.background-color]="'#'+ item.value"></span>
                                <span class="color-label">{{item.label}}</span>
                            </div>
                        </ng-template>
                        <ng-template let-color pTemplate="item">
                            <div class="ui-helper-clearfix color-item">
                                <div *ngIf="color.value !== ''" class="color-ball"
                                    [style.background-color]="'#'+ color.value"></div>
                                <div class="f-right">{{color.label}}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="ui-grid-col-1 t-center">
                    <i class="icon-trash delete" (click)="delete(index)"></i>
                </div>
            </div>
        </div>
        <div class="button-section m-t-10 f-right">
            <button class="btn btn-action-ok w-600 f-13 m-b-0" label="Save" (click)="save()">Save</button>
        </div>
        <div class="btn-group m-t-10 f-right">
            <button class="btn btn-action-cancel w-600 f-13 m-b-0" label="Cancel" (click)="close()">Cancel</button>
        </div>
    </div>
</p-dialog>
<confirm-dialog titleText="Remove Team" message="Do you wish to remove this team?"></confirm-dialog>