import { share } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Flight } from "../models";
import { Observable, Subject } from "rxjs";
import { LeaderboardService } from "./LeaderboardService";
import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { HttpServices } from "./HttpServices";

@Injectable()
export class FlightService {
  flights$: Observable<Flight[]>;
  private _fightsObserver = new Subject<Flight[]>();

  private _dataStore: {
    fights: Flight[];
  };

  headers: HttpHeaders = new HttpHeaders();

  constructor(
    public http: HttpClient,
    private leaderboardService: LeaderboardService,
    private toastr: ToastrService,
    public httpService: HttpServices
  ) {
    this._dataStore = { fights: [] };
    this.flights$ = this._fightsObserver.asObservable().pipe(share());
    this.headers.append("Content-Type", "application/json; charset=utf-8");
  }

  setFlights(fights: Array<Flight>) {
    this._dataStore.fights = _.clone(this._dataStore.fights);
  }
  loadAll(competitionId: string, roundID: string): void {
    var url = `${environment.apiUrl}/api/Competitions/${competitionId}/Round/${roundID}/Flights`;
    this.httpService.get(url).subscribe(
      (data: Array<Flight>) => {
        this._dataStore.fights = data;
        this.setFlights(this._dataStore.fights);
        this._fightsObserver.next(this._dataStore.fights);
      },
      (error) => console.log("Could not load flights.")
    );
  }

  addNextFlight(competitionID: string, roundID: string): Observable<Flight> {
    var flightObserver = new Subject<Flight>();

    this.httpService
      .postTyped<Flight>(
        `${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/Flights/Next`,
        ""
      )
      .subscribe(
        (flight) => {
          this._dataStore.fights.push(flight);
          this.setFlights(this._dataStore.fights);
          this._fightsObserver.next(this._dataStore.fights);
          flightObserver.next(flight);
          flightObserver.complete();
          this.toastr.success("Tee Time added");
          return flight;
        },
        (error) => console.log("Could not create flight.")
      );
    return flightObserver;
  }

  add(
    competitionID: string,
    roundID: string,
    addFlight: Flight
  ): Observable<Flight> {
    var flightObserver = new Subject<Flight>();
    this.httpService
      .post(
        `${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/Flights`,
        addFlight        
      )
      .subscribe(
        (flight: Flight) => {
          this._dataStore.fights.push(flight);
          this.setFlights(this._dataStore.fights);
          this._fightsObserver.next(this._dataStore.fights);
          this.toastr.success("Tee Time added");
          flightObserver.next(flight);
          flightObserver.complete();
          return flight;
        },
        (error) => console.log("Could not create flight.")
      );
    return flightObserver;
  }

  update(
    competitionID: string,
    roundID: string,
    updateFlight: Flight
  ): Observable<Flight> {
    var flightObserver = new Subject<Flight>();
    this.httpService
      .put(
        `${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/Flights`,
        updateFlight
      )
      .subscribe(
        (flight: Flight) => {
          this._dataStore.fights.forEach((flight, i) => {
            if (updateFlight.flightID === flight.flightID) {
              this._dataStore.fights[i] = flight;
            }
          });
          this.setFlights(this._dataStore.fights);
          this._fightsObserver.next(this._dataStore.fights);
          this.toastr.success("Tee Time updated");
          flightObserver.next(flight);
          flightObserver.complete();
          return flight;
        },
        (error) => console.log("Could not update flight.")
      );
    return flightObserver;
  }

  remove(
    competitionID: string,
    roundID: string,
    flightID: number
  ): Observable<Flight> {
    var flightObserver = new Subject<Flight>();
    var url = `${environment.apiUrl}/api/Competitions/${competitionID}/Round/${roundID}/Flights/${flightID}`;
    var self = this;
    this.httpService.delete(url).subscribe(
      (response) => {
        var index = _.findIndex(self._dataStore.fights, { flightID: flightID });
        if (index !== -1) {
          flightObserver.next(self._dataStore.fights[index]);
          flightObserver.complete();
          self._dataStore.fights.splice(index, 1);
          this.setFlights(self._dataStore.fights);
        }
        this.toastr.success("Tee Time removed");

        this.leaderboardService
          .recalculateLeaderboard(roundID)
          .subscribe({ error: (e) => console.error(e) });
        self._fightsObserver.next(self._dataStore.fights);
      },
      (error) => console.log("Could not delete flight.")
    );
    return flightObserver;
  }

  validateMatchplayTeams(roundId: number): Observable<string[]> {
    const url = `${environment.apiUrl}/api/flights/validate-matchplay-teams/${roundId}`;
    return this.httpService.get(url);
  }  
}
