<div class="page-content page-wizard portlets ui-sortable">
    <div class="edit-pnl">
        <div class="row SetupWizard_playerPool" id="table-section">
            <div class="row SetupWizard">
                <a [routerLink]="['/tournaments/'+competitionID+'/edit']">
                    <div class="col-md-4 SetupWizard_step SetupWizard_step2">
                        <span class="span_active">Setup</span>
                    </div>
                </a>
                <div class="col-md-4 SetupWizard_step SetupWizard_step1">
                    <span>Players</span>
                </div>
                <a [routerLink]="['/tournaments/'+competitionID+'/round/' + firstRoundId + '/createleaderboard']">
                    <div class="col-md-4 SetupWizard_step SetupWizard_step3">
                        <div class="arrow-right"></div>
                        <span class="span_active">Leaderboards</span>
                    </div>
                </a>
            </div>
        </div>
        <div class="row">
            <div class="btn-group  pull-right" style="margin-top:10px; padding-right:90px;"
                *ngIf="roundsSelect.length>1">
                <button type="button" class="btn btn-white dropdown-toggle" data-toggle="dropdown">
                    <span>{{selectorText}}</span>
                    <span class="caret"></span>
                </button>
                <span class="dropdown-arrow dropdown-arrow-inverse"></span>
                <ul class="dropdown-menu dropdown-inverse" role="menu">
                    <li *ngFor="let round of roundsSelect">
                        <a [routerLink]="['/tournaments/'+competitionID+'/round/'+round.value+'/playerlist']">Round
                            {{round.label}}</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-12">
                <fieldset class="wizard-div current wizard-arrow">
                    <div class="row row_Uploads">
                        <div class="button-container align_left">
                            <div class="btn-group" *toggleFeature="'playerUpload';offeringLevel:offeringLevel">
                                <player-sheet-upload (sheetUploaded)="sheetUploaded()" [competitionID]="competitionID"
                                    [roundID]="roundID"></player-sheet-upload>
                            </div>
                            <div class="btn-group">
                                <button id="recalculateHandicaps" class="btn btn-default m-b-5"
                                    (click)="recalculateHandicapsCall()" style="width:150px;">Adjust HCP</button>
                            </div>
                        </div>
                        <div class="button-container">
                            <div class="search-input-container">
                                <img src="../assets/icons/overview-icons/search.svg" alt="" class="img-search">
                                <input #gb type="text" pInputText size="50" placeholder="Search Players....">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <p-dataTable [emptyMessage]="emptyMessage" #dt [value]="playerTableList"
                            class="table table-dynamic" [rows]="12" [paginator]="true" selectionMode="single"
                            [(selection)]="selectedPlayer" (onRowSelect)="onRowSelect($event)" [globalFilter]="gb">
                            <p-column field="nationalityCode" [sortable]="true" [style]="{'width':'50px'}">
                                <ng-template pTemplate="body" let-playerCreateDetail="rowData">
                                    <img class="smallFlag"
                                        *ngIf="playerCreateDetail!=null && playerCreateDetail.nationalityCode!=''"
                                        src="{{environment.imagesCDNUrl}}flags/{{playerCreateDetail.nationalityCode}}.svg" />
                                </ng-template>
                            </p-column>
                            <p-column field="displayName" header="Name" [sortable]="true"
                                styleClass="leftColumnContent">
                                <ng-template pTemplate="body" let-playerCreateDetail="rowData">
                                    <span class="edit-cell t-ellipsis">{{playerCreateDetail.firstName}}
                                        {{playerCreateDetail.surname}}</span>
                                </ng-template>
                            </p-column>
                            <p-column field="handicap" class="edit-info" header="Index" [style]="{'width':'60px'}"
                                [sortable]="true" [hidden]="shotsDisplay" [style]="{'width':'120px'}">
                                <ng-template pTemplate="body" let-playerCreateDetail="rowData">
                                    <span class="edit-cell">{{playerCreateDetail.vparHandicap ?
                                        playerCreateDetail.vparHandicap : playerCreateDetail.handicap}}</span>
                                </ng-template>
                            </p-column>
                            <p-column field="handicap" class="edit-info" header="Game HCP" [style]="{'width':'100px'}"
                                [sortable]="true" [hidden]="shotsDisplay" [style]="{'width':'120px'}">
                                <ng-template pTemplate="body" let-playerCreateDetail="rowData">
                                    <span>{{playerCreateDetail.flightHandicap}}</span>
                                </ng-template>
                            </p-column>
                            <p-column field="shots" class="edit-info" header="HCP (Shots)" [sortable]="true"
                                [hidden]="!shotsDisplay" [style]="{'width':'120px'}">
                                <ng-template pTemplate="body " let-playerCreateDetail="rowData">
                                    <span class="edit-cell">{{playerCreateDetail.vparHandicap ?
                                        playerCreateDetail.vparHandicap : playerCreateDetail.handicap}}</span>
                                    <span
                                        *ngIf="playerCreateDetail.shots||playerCreateDetail.shots==0">({{playerCreateDetail.shots}})</span>
                                </ng-template>
                            </p-column>
                            <p-column class="edit-info" header="Email" field="emailAddress" [sortable]="true">
                                <ng-template pTemplate="body" let-playerCreateDetail="rowData">
                                    <span
                                        *ngIf="playerCreateDetail.emailAddress!=''">{{playerCreateDetail.emailAddress}}</span>
                                    <span class="editable-empty" style="display: inline;"
                                        *ngIf="playerCreateDetail.emailAddress==''">Empty</span>
                                </ng-template>
                            </p-column>
                            <p-column header="Team" field="teamTitle" [sortable]="true"
                                [hidden]="!teamDisplay"></p-column>
                            <p-column header="Type" field="flightMemberTypeText" [sortable]="true"
                                [style]="{'width':'120px'}">
                                <ng-template pTemplate="body" let-playerCreateDetail="rowData">
                                    <span
                                        *ngIf="playerCreateDetail.flightMemberTypeText!=''">{{playerCreateDetail.flightMemberTypeText}}</span>
                                    <span class="editable-empty" style="display: inline;"
                                        *ngIf="playerCreateDetail.flightMemberTypeText==''">-</span>
                                </ng-template>
                            </p-column>
                            <p-column header="Hole" field="startHole" [sortable]="true"
                                [style]="{'width':'60px'}"></p-column>
                            <p-column header="Time" field="teeOffTime" [sortable]="true"
                                [style]="{'width':'60px'}"></p-column>
                            <p-column header="M/F" field="genderText" [sortable]="true"
                                [style]="{'width':'60px'}"></p-column>                          
                        </p-dataTable>
                        <team-management [competitionID]="competitionID" [currentRound]="currentRound"
                            [(displayTeamDialog)]="displayTeamDialog" (teamsSave)="recalculateLB(roundID)"
                            [teamColoursSelect]="teamColoursSelect" [teams]="teams"
                            [teamDeleteEnabled]="roundsSelect.length==1 && gameType!=2"></team-management>
                        <player-modal [currentRound]="currentRound" [decimalScoring]="decimalScoring"
                            [(displayPlayerDialog)]="displayPlayerDialog" [newPlayer]="newPlayer"
                            [playerCreateDetail]="playerCreateDetail" [holes]="holes"
                            [flights]="flights"></player-modal>
                    </div>
                    <div #divFlightsContainer class="row p-t-20">
                        <div #divFlightsRenderer class="col-md-9">
                            <flights-renderer [teamColoursSelect]="teamColoursSelect" [editable]="true" [holes]="holes"
                                [teams]="teams" [round]="currentRound" [flightType]="flightType"
                                [flights]="flights"></flights-renderer>
                        </div>
                        <div #divPlayerPool class="col-md-3 playerpoolContainer">
                            <button class="btn btn-default m-b-5" (click)="addNextFlight()">Add Tee Time</button>
                            <button *ngIf="teamDisplay" class="btn btn-default m-b-5" (click)="manageTeams()">Manage
                                Teams</button>
                            <playerpool [competitionID]="competitionID" [roundID]="roundID"></playerpool>
                        </div>
                        <div class="col-md-9 sf-controls clearfix p-t-10">
                            <a class="prev-btn sf-left sf-btn sf-btn-prev"
                                [routerLink]="['/tournaments/'+competitionID+'/edit']" *ngIf="competitionID!=null">
                                <div class="btn btn-default div-btn">BACK</div>
                            </a>
                            <a class="next-btn sf-right sf-btn sf-btn-next"
                                (click)="validateAndRedirect()"
                                *ngIf="competitionID!=null">
                                <div class="btn btn-default pull-right div-btn">NEXT</div>
                            </a>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
    <confirm-dialog titleText="Rerun Handicap adjustment"
        message="Running this handicap adjustment will update every player's game handicap based on course difficulty.
If you have manually edited anyones game handicap already this will be affected and you will need to amend these again once you have run this tool."></confirm-dialog>
</div>

<p-dialog class="errors-modal" [(visible)]="displayMatchplayErrorsModal" 
          [responsive]="true" [modal]="true" [blockScroll]="true" 
          [resizable]="false" [draggable]="false" [focusOnShow]="false">
    <p-header>
        <span #closeButton tabindex="0" class="close-button" (click)="closeModal()"></span>
        Matchplay Errors
    </p-header>
    <div style="width:100%;">
        <div style="display: flex; flex-direction: column">           
            <div class="errors-modal-container">                
                <ul>
                    <li *ngFor="let error of matchplayErrors">
                        {{ error }}
                    </li>
                </ul>              
            </div>
        </div>
        <div class="col-md-12 button-section">
            <div class="btn-group pull-right m-r-0">
                <button class="btn btn-default div-btn" (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</p-dialog>

