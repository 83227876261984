import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, HostListener, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/primeng';
import { DomainHelpers } from '../../../helpers/domain.helpers';
import { Profile, Flight, FlightTeamGroup, Team, FlightMember, Competition, Course, Round, Hole, Venue, PlayerCreateDetail } from '../../../models'
import { FlightService, FlightMembersService, TeamsService, PlayerPoolService, ResultService, CourseService, FlightMemberService, LeaderboardService, RoundService } from '../../../services';
import { Subject ,  Observable, combineLatest } from 'rxjs';
import { ConfirmDialog } from '../../../shared/index';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

export enum TeamGroupType {
    Individual = 1,
    Pairs = 2,
    Single = 3,
    Flight = 4
}

@Component({
    templateUrl: 'tournament-playerlist.component.html',
    styleUrls: ['./tournament-playerlist.component.scss']
})

export class TournamentPlayerlistComponent implements OnInit, OnDestroy {
    playerTableList: Array<PlayerCreateDetail>;
    competitionID: string = '';
    currentRound: Round = new Round();
    firstRoundId: number;
    roundID: string = '';
    flightType: number = 4;
    teamType: number;
    gameType: number;
    selectedPlayer: PlayerCreateDetail;
    flightMembers: FlightMember[] = new Array<FlightMember>();
    teams: Team[] = [];

    public emptyMessage: string = "Loading...";
    playerCreateDetail: PlayerCreateDetail;
    newPlayer: boolean = false;
    displayTeamEditDialog: boolean = false;
    flights: Flight[] = new Array<Flight>();
    holes: SelectItem[] = [];
    roundsSelect: SelectItem[] = [];
    playerTypes: SelectItem[] = [];
    teamColoursSelect: SelectItem[] = [];
    decimalScoring: boolean = false;
    active: boolean = true;
    displayTeamDialog: boolean = false;
    displayPlayerDialog: boolean = false;
    teamDisplay: boolean = true;
    shotsDisplay: boolean = true;
    isSharedBall: boolean = false;
    selectorText: string;
    isTeeAdjustment: boolean = false;
    showAdjustHandicap: boolean = false;
    displayMatchplayErrorsModal: boolean =false;
    matchplayErrors :string[]; 
    flightCount = 0;
    $stop: Subject<boolean> = new Subject<any>();
    @ViewChild('divPlayerPool', { static: false }) divPlayerPool: ElementRef;
    @ViewChild('divFlightsContainer', { static: false }) divFlightsContainer: ElementRef;
    @ViewChild('divFlightsRenderer', { static: false }) divFlightsRenderer: ElementRef;
    @ViewChild(ConfirmDialog, { static: false }) confirmDialog: ConfirmDialog;      
    @ViewChild('closeButton', { static: false }) closeButton: ElementRef;  
    @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;

    offeringLevel: number;
    public environment = environment;

    constructor(private route: ActivatedRoute,
        private flightMembersService: FlightMembersService,
        public teamsService: TeamsService,
        public playerPoolService: PlayerPoolService,
        public flightService: FlightService,
        public courseService: CourseService,
        public resultService: ResultService,
        private renderer: Renderer2,
        public flightMemberService: FlightMemberService,
        private leaderboardService: LeaderboardService,
        private roundService: RoundService,
        private toastr: ToastrService,
        private router: Router) {

        this.teamColoursSelect = DomainHelpers.SelectLists.getTeamColours();
        this.playerTypes = DomainHelpers.SelectLists.getPlayerTypes();

        this.offeringLevel = this.route.parent.snapshot.data['branding'].offeringType;
    }

    setRoundDetails(currentRound: Round): void {
        this.flightType = currentRound.flightType;
        this.teamType = currentRound.teamType;
        this.gameType = currentRound.gameType;
        this.shotsDisplay = DomainHelpers.RoundHelper.shotsDisplay(currentRound);
        this.teamDisplay = DomainHelpers.RoundHelper.getTeamDisplay(currentRound);
        this.isSharedBall = this.currentRound.isSharedBall;
    }

    @HostListener("window:scroll", [])
    onWindowScroll() {
        this.adjustPlayerPoolDiv();
    }

    @HostListener("window:resize", [])
    onResize() {
        this.renderer.setStyle(this.divPlayerPool.nativeElement, 'top', "");
        this.renderer.setStyle(this.divPlayerPool.nativeElement, 'left', "");
        this.renderer.setStyle(this.divPlayerPool.nativeElement, 'width', "");
        this.renderer.setStyle(this.divPlayerPool.nativeElement, 'position', 'static');
        this.adjustPlayerPoolDiv();
    }

    ngAfterViewInit() {        
        if (this.displayMatchplayErrorsModal) {
          setTimeout(() => {
            this.closeButton.nativeElement.focus();
          }, 0); 
        }
      }
    
      focusCloseBtn() {
        this.closeBtn.nativeElement.focus();
      }

    adjustPlayerPoolDiv(): void {
        const divFlightsContainer = this.divFlightsContainer.nativeElement as HTMLElement;


        if (
            divFlightsContainer &&
            window.scrollY > divFlightsContainer.offsetTop &&
            this.divFlightsRenderer.nativeElement.offsetHeight > this.divPlayerPool.nativeElement.offsetHeight
        ) {
            this.renderer.setStyle(this.divPlayerPool.nativeElement, 'top', '0');
            this.renderer.setStyle(this.divPlayerPool.nativeElement, 'left', this.divPlayerPool.nativeElement.getBoundingClientRect().left + 'px');
            this.renderer.setStyle(this.divPlayerPool.nativeElement, 'width', this.divPlayerPool.nativeElement.getBoundingClientRect().width + 'px');
            this.renderer.setStyle(this.divPlayerPool.nativeElement, 'position', 'fixed');
        } else {
            this.renderer.setStyle(this.divPlayerPool.nativeElement, 'top', '');
            this.renderer.setStyle(this.divPlayerPool.nativeElement, 'left', '');
            this.renderer.setStyle(this.divPlayerPool.nativeElement, 'width', '');
            this.renderer.setStyle(this.divPlayerPool.nativeElement, 'position', 'static');
        }
    }


    ngOnInit() {

        var self = this;
        this.route.data.pipe(takeUntil(this.$stop)).subscribe(data => {
            var routeComp = <Competition>data['tournament'];
            this.decimalScoring = routeComp.useDecimalScoring || false;
            this.roundID = data['roundId'];
            this.competitionID = routeComp.competitionID.toString();
            this.firstRoundId = routeComp.rounds[0].roundID;
            this.currentRound = _.find(routeComp.rounds, { 'roundID': +self.roundID });
            this.setRoundDetails(this.currentRound);
            this.roundsSelect = this.createRoundList(routeComp.rounds);
            this.loadData(this.competitionID, this.roundID);
            this.isTeeAdjustment = routeComp.isTeeAdjustment;
            this.showAdjustHandicap = this.isTeeAdjustment ? true : false;
        });

        this.flightMemberService.addedFlightMember$.subscribe(flightMember => {
            let flight = <Flight>_.find(self.flights, { 'flightID': +flightMember.flightID });
            let team = <Team>_.find(self.teams, { 'teamID': +flightMember.teamID });
            this.playerCreateDetail = this.convertProfileToPlayerCreateDetail(new Profile(), flightMember, flight, team);
            this.newPlayer = true;
            this.playerCreateDetail.addToBallSlot = true;
            this.displayPlayerDialog = true;
        });

        this.courseService.course$.pipe(takeUntil(this.$stop)).subscribe(courses => {
            let currentCourse = _.find(courses, { 'courseID': this.currentRound.courseID });
            this.holes = DomainHelpers.SelectLists.getHoleDetails(currentCourse);
        });

        this.flightService.flights$
            .pipe(takeUntil(this.$stop))
            .subscribe((flights: Flight[]) => {
                //Flights have been removed. Refresh pool.
                if (flights.length < self.flightCount) {
                    self.playerPoolService.loadAll(self.competitionID);
                    self.flightMembersService.loadAll(self.competitionID, self.roundID);
                }
                self.flights = flights;
                self.flightCount = flights.length;
            });

        this.flightMembersService.flightMembers$
            .pipe(takeUntil(this.$stop))
            .subscribe((flightMembers: Array<FlightMember>) => {
                this.flightMembers = flightMembers;
            });


        combineLatest(this.teamsService.teams$, self.flightMembersService.flightMembers$,
            function (teams: Array<Team>, flightMembers: Array<FlightMember>) {
                if (flightMembers) {
                    flightMembers.forEach(flightMember => {
                        flightMember.profile.handicap = Math.round(flightMember.profile.handicap * 10) / 10;
                    });
                }
                if (teams) {
                    teams.forEach(function (team) {
                        team.flightMembers = _.filter(flightMembers, { 'teamID': team.teamID });
                        var color = _.find(self.teamColoursSelect, { 'value': team.colour });
                        if (color) {
                            if (color.value != '') {
                                team.colourText = color.label;
                            }
                        }
                    });
                }
                return teams;
            })
            .pipe(takeUntil(this.$stop))
            .subscribe((data: Array<Team>) => {
                //Basically this is a hack I have had to add to cause input change cycle. Clear array and then add
                this.teams = [];
                this.teams.push.apply(this.teams, _.sortBy(data, 'teamID').reverse())
            });

        combineLatest(self.flightMembersService.flightMembers$, self.flightService.flights$, self.teamsService.teams$,
            function (flightMembers: Array<FlightMember>, flights: Array<Flight>, teams: Array<Team>) {
                let teamGroupType = TeamGroupType.Individual;
                let teamType = self.teamType;
                var upperBoundBall = self.flightType + 1;
                var isMatchplay = self.gameType === 2;
                if (teamType == 40 || teamType == 50 || teamType == 80) {
                    teamGroupType = TeamGroupType.Single;
                }
                if (teamType == 30 || teamType == 60 || teamType == 70 || (teamType == 50 && isMatchplay)) {
                    teamGroupType = TeamGroupType.Pairs;
                }
                if (teamType == 80) {
                    teamGroupType = TeamGroupType.Flight;
                }
                flights.forEach(function (flight) {
                    flight.flightTeamGroups = [];
                    let members = _.sortBy(_.filter(flightMembers, { 'flightID': flight.flightID }), 'ball');
                    flight.flightMembers = members;
                    if (teamGroupType === TeamGroupType.Individual) {
                        _.range(1, upperBoundBall).forEach(ball => {
                            let flightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                            flightTeamGroup.displayHeader = isMatchplay || teamType == 20;
                            if (isMatchplay) {
                                flightTeamGroup.allowReassign = false;
                                flightTeamGroup.showShots = true;

                                var team = null;
                                if (_.includes([1, 3], ball)) {
                                    let flightMemberTeam = _.first(flightMembers.filter(a => a.ball == 1 || a.ball == 3));
                                    if (flightMemberTeam !== undefined) {
                                        team = <Team>_.find(teams, { 'teamID': +flightMemberTeam.teamID });
                                    }
                                }
                                if (_.includes([2, 4], ball)) {
                                    let flightMemberTeam = _.first(flightMembers.filter(a => a.ball == 2 || a.ball == 4));
                                    if (flightMemberTeam !== undefined) {
                                        team = <Team>_.find(teams, { 'teamID': +flightMemberTeam.teamID });
                                    }
                                }
                                if (team) {
                                    self.FlightMemberGroupTeamMap(flightTeamGroup, team);
                                }
                            }

                            let flightMember = <FlightMember>_.find(flight.flightMembers, { 'ball': +ball });
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                flightTeamGroup = self.FlightMemberGroupTeamMap(flightTeamGroup, team);
                            } else {
                                flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                            }
                            flightTeamGroup.flightMembers.push(flightMember);
                            flight.flightTeamGroups.push(flightTeamGroup);
                        });
                    }
                    if (teamGroupType === TeamGroupType.Single) {
                        let individualFlightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                        individualFlightTeamGroup.displayHeader = true;
                        individualFlightTeamGroup.allowReassign = !isMatchplay;
                        //Can't occur matchplay is an individual or pairs format
                        _.range(1, upperBoundBall).forEach(ball => {
                            var flightMember = <FlightMember>_.find(flight.flightMembers, { 'ball': +ball });
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                individualFlightTeamGroup = self.FlightMemberGroupTeamMap(individualFlightTeamGroup, team);
                            } else {
                                flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                            }
                            individualFlightTeamGroup.flightMembers.push(flightMember);
                        });
                        flight.flightTeamGroups.push(individualFlightTeamGroup);
                    }

                    if (teamGroupType === TeamGroupType.Flight) {
                        let flightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                        flightTeamGroup.displayHeader = true;
                        flightTeamGroup.mergedCells = self.isSharedBall;
                        flightTeamGroup.allowReassign = !isMatchplay;
                        _.range(1, upperBoundBall).forEach(ball => {
                            var flightMember = <FlightMember>_.find(flight.flightMembers, { 'ball': +ball });
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                flightTeamGroup = self.FlightMemberGroupTeamMap(flightTeamGroup, team);
                                flightTeamGroup.mergedValue = flightMember.handicap;
                            } else {
                                flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                            }
                            flightTeamGroup.flightMembers.push(flightMember);
                        });
                        flight.flightTeamGroups.push(flightTeamGroup);
                    }
                    if (teamGroupType === TeamGroupType.Pairs) {
                        //For matchplay we need to assign team titles. This will require all flightMembers
                        let firstFlightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                        firstFlightTeamGroup.displayHeader = true;
                        firstFlightTeamGroup.mergedCells = self.isSharedBall;                        
                        if (isMatchplay) {
                            firstFlightTeamGroup.allowReassign = false;
                            firstFlightTeamGroup.showShots = true;
                            let flightMember = _.first(flightMembers.filter(a => a.ball == 1 || a.ball == 2));
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                if (team) {
                                    self.FlightMemberGroupTeamMap(firstFlightTeamGroup, team);
                                }
                            }
                        }
                        let secondFlightTeamGroup = new FlightTeamGroup(self.decimalScoring);
                        secondFlightTeamGroup.displayHeader = true;
                        secondFlightTeamGroup.mergedCells = self.isSharedBall;
                        if (isMatchplay) {
                            secondFlightTeamGroup.allowReassign = false;
                            secondFlightTeamGroup.showShots = true;
                            let flightMember = _.first(flightMembers.filter(a => a.ball == 3 || a.ball == 4));
                            if (flightMember) {
                                let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                // secondFlightTeamGroup.team = team;
                                if (team) {
                                    self.FlightMemberGroupTeamMap(secondFlightTeamGroup, team);
                                }
                            }
                        }
                        _.range(1, upperBoundBall).forEach(ball => {
                            let flightMember = <FlightMember>_.find(flight.flightMembers, { 'ball': +ball });
                            if (_.includes([1, 2], ball)) {

                                if (flightMember) {
                                    let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                    firstFlightTeamGroup = self.FlightMemberGroupTeamMap(firstFlightTeamGroup, team);
                                } else {
                                    flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                                }
                                let flightMember1 = <FlightMember>_.find(flight.flightMembers, { 'ball': 1 });
                                let flightMember2 = <FlightMember>_.find(flight.flightMembers, { 'ball': 2 });
                                if (flightMember1 && flightMember2) {
                                    if (firstFlightTeamGroup.showShots) {
                                        firstFlightTeamGroup.mergedValue = flightMember1.shots;
                                    }
                                    else {
                                        firstFlightTeamGroup.mergedValue = flightMember1.handicap;
                                    }
                                }
                                firstFlightTeamGroup.flightMembers.push(flightMember);
                            }
                            if (_.includes([3, 4], ball)) {
                                if (flightMember) {
                                    let team = <Team>_.find(teams, { 'teamID': +flightMember.teamID });
                                    secondFlightTeamGroup = self.FlightMemberGroupTeamMap(secondFlightTeamGroup, team);
                                } else {
                                    flightMember = FlightMember.EmptyFlightMember(+self.roundID, flight.flightID, ball);
                                }
                                let flightMember3 = <FlightMember>_.find(flight.flightMembers, { 'ball': 3 });
                                let flightMember4 = <FlightMember>_.find(flight.flightMembers, { 'ball': 4 });
                                if (flightMember3 && flightMember4) {
                                    if (secondFlightTeamGroup.showShots) {
                                        secondFlightTeamGroup.mergedValue = flightMember3.shots;
                                    }
                                    else {
                                        secondFlightTeamGroup.mergedValue = flightMember3.handicap;
                                    }
                                }
                                secondFlightTeamGroup.flightMembers.push(flightMember);

                            }
                        });
                        flight.flightTeamGroups.push(firstFlightTeamGroup);
                        flight.flightTeamGroups.push(secondFlightTeamGroup);
                    }
                });
                return flights;
            })
            .pipe(takeUntil(this.$stop))
            .subscribe((data) => {
                self.flights = data;
            });

        combineLatest(this.flightMembersService.flightMembers$, this.flightService.flights$, this.teamsService.teams$,
            function (flightMembers, flights, teams) { return self.createPlayerTableList(flightMembers, flights, teams); })
            .pipe(takeUntil(this.$stop))
            .subscribe(data => {
                this.playerTableList = data;
                this.emptyMessage = "No players found";
            });
            console.log("currentRound: ", this.currentRound)
    }

    private FlightMemberGroupTeamMap(flightTeamGroup: FlightTeamGroup, team: Team): FlightTeamGroup {
        if (team != null && (!flightTeamGroup.team || flightTeamGroup.team.teamID == 0)) {
            flightTeamGroup.title = team.title;
            flightTeamGroup.colour = team.colour;
            flightTeamGroup.colourText = team.colourText;
            flightTeamGroup.teamID = team.teamID;
            flightTeamGroup.team = _.clone(team);
        }
        return flightTeamGroup;
    }

    sheetUploaded() {
        this.loadData(this.competitionID, this.roundID);
        this.recalculateLB(this.roundID);
    }

    loadData(competitionID: string, roundID: string) {
        this.playerPoolService.loadAll(competitionID);
        this.teamsService.loadAll(competitionID);
        this.courseService.load(this.currentRound.courseID);
        this.flightService.loadAll(competitionID, roundID);
        this.flightMembersService.loadAll(competitionID, roundID);
    }

    addNextFlight(): void {
        this.flightService.addNextFlight(this.competitionID, this.roundID);
    }

    createRoundList(rounds: any): Array<SelectItem> {
        var roundDetails = new Array<SelectItem>();
        var i = 0;
        rounds.forEach((round: Round) => {
            i++;
            if (+this.roundID == round.roundID) {
                this.selectorText = "Round " + i.toString();
            };
            roundDetails.push({
                label: i.toString(),
                value: round.roundID.toString()
            });
        });
        return roundDetails;
    }

    showDialogToAdd() {
        this.newPlayer = true;
        this.playerCreateDetail = new PlayerCreateDetail();
        this.displayPlayerDialog = true;
    }

    manageTeams() {
        this.displayTeamDialog = true;
    }

    refreshResults() {
        this.resultService.refresh(this.currentRound);
    }

    recalculateHandicapsCall() {
        var self = this;
        var callBack = function (event) {
            self.roundService.recalculateHandicap(self.currentRound.roundID);
            window.location.reload();
        }
        self.confirmDialog.showDialog(callBack);
    }

    createPlayerTableList(flightMembers: Array<FlightMember>, flights: Array<Flight>, teams: Array<Team>): Array<PlayerCreateDetail> {
        var comp = this;
        var playerTableList = new Array<PlayerCreateDetail>();

        flightMembers.forEach(member => {
            var flight = _.find(flights, 'flightID', member.flightID);
            var team = _.find(teams, 'teamID', member.teamID)
            var playerCD = comp.convertProfileToPlayerCreateDetail(member.profile, member, flight, team);
            playerCD.competitionID = +comp.competitionID;
            playerTableList.push(playerCD);
        });

        playerTableList.forEach(pcd => {
            pcd.handicap = Math.round(pcd.handicap * 10) / 10;
        });
        return playerTableList;
    }

    convertProfileToPlayerCreateDetail(profile: Profile, flightMember?: FlightMember, flight?: Flight, team?: Team): PlayerCreateDetail {
        var playerCreateDetail: PlayerCreateDetail = jQuery.extend(new PlayerCreateDetail(), profile);
        if (flightMember != null && flight != null) {
            var startTimeMoment = moment.utc(flight.teeOffTime).format("HH:mm");
            playerCreateDetail.teeOffTime = startTimeMoment;
            playerCreateDetail.startHole = flight.startHole;
            playerCreateDetail.flightID = flight.flightID;
            playerCreateDetail.roundID = flight.roundID;
            playerCreateDetail.handicap = profile.handicap;
            playerCreateDetail.flightMemberID = flightMember.flightMemberID;
            playerCreateDetail.ball = flightMember.ball;
            playerCreateDetail.flightHandicap = flightMember.handicap;
            playerCreateDetail.shots = flightMember.shots;
            playerCreateDetail.flightMemberType = flightMember.flightMemberType;
            playerCreateDetail.vparHandicap = flightMember.vparHandicap;
            if (flightMember.flightMemberType) {
                playerCreateDetail.flightMemberTypeText = DomainHelpers.SelectLists.getPlayerTypeText(flightMember.flightMemberType.toString());
            }
        }
        if (team) {
            playerCreateDetail.teamTitle = team.title;
        }

        playerCreateDetail.handicap = Math.round(playerCreateDetail.handicap * 10) / 10;

        return playerCreateDetail;
    }

    onRowSelect(event) {
        this.newPlayer = false;
        this.playerCreateDetail = _.clone(event.data);
        this.displayPlayerDialog = true;
    }

    ngOnDestroy() {
        this.$stop.next(true);
    }

    recalculateLB(roundID: any) {
        this.leaderboardService.recalculateLeaderboard(roundID).subscribe({ error: e => console.error(e) });
    }

    rerunHandicap() {
        var self = this;
        var callBack = function () {
            self.roundService.rerunHandicap(self.competitionID, self.roundID).subscribe(res => {
                self.ngOnInit();
                self.toastr.success('Handicap adjustment complete');
            });
        }
        this.confirmDialog.showDialog(callBack);

    }

    validateAndRedirect(): void {     
        if(this.gameType == 2 )         
         this.flightService.validateMatchplayTeams(this.firstRoundId).subscribe(result => {                         
            if (!result || result.length === 0) {          
                const url = `/tournaments/${this.competitionID}/round/${this.firstRoundId}/createleaderboard`;
                this.router.navigate([url]);
            } else { 
                this.matchplayErrors = result;
                this.displayMatchplayErrorsModal = true;                     
            }
        });
        else 
        this.router.navigate([`/tournaments/${this.competitionID}/round/${this.firstRoundId}/createleaderboard`]);
    }

    closeModal() {
        this.displayMatchplayErrorsModal = false;
    }
}
